import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Switch, Route } from 'react-router-dom'
import { Layout } from 'antd'

import {
  TopBar,
  SideBar,
  Container,
} from 'components'
import { actions as actionsSideBar } from 'components/SideBar/actions'
import { actions as actionsStore } from 'pages/Store/actions'
import menuItems from 'components/SideBar/menu-items'
import asyncPage from './AsyncPage'
import './BaseLayout.sass'

const menuKey = (location) => {
  if (location.pathname === '/') return 0

  const menus = menuItems().flatMap((menu) => {
    if (menu.children) {
      return menu.children.flatMap(children => ({
        key: children.key,
        router: children.router,
      }))
    }
    return {
      key: menu.key,
      router: menu.router,
    }
  })

  const menuList = menus.find(menu => menu.router === location.pathname)
  return menuList ? menuList.key : 0
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actionsSideBar,
  fetchCart: actionsStore.fetchCart,
}, dispatch)

const Home = asyncPage(() => import('./Home/Home'))
const BaseAdmin = asyncPage(() => import('./Admin/BaseAdmin'))
const BaseReports = asyncPage(() => import('./Reports/BaseReports'))
const BaseStore = asyncPage(() => import('./Store/BaseStore'))
const NotFound = asyncPage(() => import('./NotFound/NotFound'))

class BaseLayout extends Component {
  componentDidMount() {
    const { fetchCart } = this.props

    this.handleCollapsed()
    window.addEventListener('resize', this.handleCollapsed)
    fetchCart()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleCollapsed)
  }

  handleCollapsed = () => {
    const { location, setSideBarProps } = this.props
    setSideBarProps(menuKey(location), (window.innerWidth <= 425))
  }

  render() {
    return (
      <Fragment>
        <TopBar />
        <Layout className="base-layout">
          <SideBar />
          <Container>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/admin" component={BaseAdmin} />
              <Route path="/reports" component={BaseReports} />
              <Route path="/store" component={BaseStore} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Container>
        </Layout>
      </Fragment>
    )
  }
}

export default connect(null, mapDispatchToProps)(BaseLayout)
