import {
  all, takeLatest, put, call
} from 'redux-saga/effects'
import api from 'services/api'
import { t } from 'ab18n'
import { notification } from 'antd'

import errorsFormat from 'shared/errors-format'
import history from 'shared/history'
import { actions, types } from './actions'

const setNotification = (message, duration) => (
  notification.error({ message, duration })
)

function errorsFormatException(error, message, setErrors = null) {
  const { response: { data: { errors }, status } } = error

  if (status === 500) {
    setNotification(message)
  } else if (setErrors && status !== 403) {
    setErrors(errors)
  } else {
    errorsFormat(error, message)
  }
}

function* fetchAllSaga(action) {
  const {
    meta: {
      type,
      filter,
      after,
      setErrors,
    }
  } = action

  yield put(actions.setLoading(true))
  try {
    const response = yield call(
      api.get,
      `/admin/store/${type}${filter || ''}`,
    )
    yield put(
      actions.setData(
        type === 'payment-methods' ? 'paymentMethods' : type,
        type === 'orders' ? response : response.data
      )
    )
    if (after) {
      after()
    }
  } catch (error) {
    console.error(error)
    if (setErrors) {
      const { response: { data: { errors } } } = error
      setErrors(errors)
    }
    errorsFormat(error, t('general.notification.error'))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* fetchUnitsSaga() {
  yield put(actions.setLoading(true))
  try {
    const response = yield call(api.get, 'admin/store/units')

    yield put(actions.setUnits(response.data))
  } catch (err) {
    console.error(err)
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* fetchDefaultersSaga() {
  yield put(actions.setLoading(true))
  try {
    const response = yield call(api.get, 'admin/store/defaulters')

    yield put(actions.setDefaulters(response))
  } catch (err) {
    console.error(err)
    errorsFormat(err, t('general.notification.error'), 2)
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* createDefaulterSaga(action) {
  const { payload } = action
  const { unit, note, type } = payload

  yield put(actions.setLoading(true))
  try {
    yield call(api.post, 'admin/store/defaulters', {
      unit,
      note,
    })
    history.push('/admin/store/finances')
    notification.success({ message: t('financial.notification.create.success'), duration: 4 })

    if (type === 'edit') {
      yield put(actions.editDefaulters(unit))
      yield put(actions.openModal())
    }
  } catch (err) {
    console.error(err)
    errorsFormat(err, t('general.notification.error'), 2)
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* patchDefaulterSaga(action) {
  const { payload } = action
  const { note } = payload

  yield put(actions.setLoadingUpdate(true))
  try {
    yield call(api.put, `admin/store/defaulters/${payload.unit}`, {
      note
    })

    notification.success({ message: t('financial.notification.create.success'), duration: 4 })
    yield put(actions.editDefaulters(payload.unit))
    yield put(actions.openModal())
  } catch (err) {
    console.error(err)
    errorsFormat(err, t('general.notification.error'), 2)
  } finally {
    yield put(actions.setLoadingUpdate(false))
  }
}

function* fetchWalletProductsAllSaga() {
  yield put(actions.setLoading(true))
  try {
    const response = yield call(api.get, 'admin/store/wallets/products',)
    yield put(actions.setWalletProducts(response.data))
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('general.notification.error'), 2)
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* fetchProductsSaga() {
  yield put(actions.setLoading(true))
  try {
    const response = yield call(api.get, 'admin/store/products?notWallet=1',)
    yield put(actions.setProducts(response.data))
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('general.notification.error'), 2)
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* createWalletProductSaga(action) {
  const { payload } = action
  yield put(actions.setLoading(true))
  try {
    yield call(api.post, 'admin/store/wallets/products', payload)
    notification.success({ message: t('wallets.notification.product.create.success'), duration: 2 })
    yield put(actions.fetchWalletProductsAll())
    yield put(actions.fetchProducts())
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('wallets.notification.product.create.error'), 2)
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* updateWalletProductSaga(action) {
  const { payload, meta } = action
  yield put(actions.setLoading(true))
  try {
    yield call(api.put, `admin/store/products/${payload}`, meta)
    notification.success({ message: t('wallets.notification.product.update.success'), duration: 2 })
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('wallets.notification.product.update.error'), 2)
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* fetchOneSaga(action) {
  const { payload, meta: { type } } = action

  yield put(actions.setLoading(true))
  try {
    const response = yield call(
      api.get,
      `/admin/store/${type}/${payload}`,
    )
    yield put(actions.setData(type, response))
  } catch (error) {
    console.error(error)
    errorsFormat(
      error,
      t('general.notification.error'),
      2,
      type === 'kits' ? '/admin/store' : `/admin/store/${type}`
    )
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* sendDataSaga(action) {
  const {
    payload,
    meta: {
      id,
      type,
      after,
      setErrors,
    }
  } = action

  yield put(actions.setLoading(true))
  try {
    if (id) {
      yield call(api.put, `/admin/store/${type}/${id}`, payload)
    } else {
      yield call(api.post, `/admin/store/${type}`, payload)
    }
    notification.success({
      message: t(
        `${
          type === 'promotions' || type === 'wallets'
            ? type
            : 'admin.store'
        }.notification.${id ? 'update' : 'create'}.success`
      ),
      duration: 2,
      onClose: () => after()
    })
  } catch (error) {
    console.error(error)
    errorsFormatException(
      error,
      t(
        `${
          type === 'promotions' || type === 'wallets'
            ? type
            : 'admin.store'
        }.notification.${id ? 'update' : 'create'}.error`
      ),
      setErrors
    )
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* generateLabelsSaga(action) {
  const { meta: { id } } = action

  yield put(actions.setLoading(true))
  try {
    const data = yield call(api.get, `/admin/store/tags/order/${id}`, {
      responseType: 'blob',
    })
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `pedido-${id}.prn`)
    document.body.appendChild(link)
    link.click()

    notification.success({
      message: t('admin.store.notification.labels.success'),
      duration: 2,
    })
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('admin.store.notification.labels.error'))
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* sendPatchDataSaga(action) {
  const { payload, meta: { id, type, after } } = action

  yield put(actions.setLoading(true))
  try {
    yield call(api.patch, `/admin/store/${type}/${id}`, payload)
    notification.success({
      message: t(
        `admin.store.notification.${id ? 'update' : 'create'}.success`
      ),
      duration: 2,
      onClose: () => after()
    })
  } catch (error) {
    console.error(error)
    errorsFormat(
      error,
      t(
        `admin.store.notification.${id ? 'update' : 'create'}.error`
      )
    )
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* cancelOrderSaga(action) {
  const { meta: { id } } = action

  yield put(actions.setLoading(true))
  try {
    const response = yield call(api.post, `/admin/store/orders/${id}/cancel`)
    yield put(actions.setData('orders', response))
    notification.success({
      message: t('admin.store.notification.order.cancel'),
      duration: 2,
    })
  } catch (error) {
    console.error(error)
    errorsFormat(
      error,
      t('admin.store.notification.order.errorCancel')
    )
  } finally {
    yield put(actions.setLoading(false))
  }
}

function* sendOrderNfSaga(action) {
  const { payload, meta: { id } } = action

  yield put(actions.setLoading(true))
  try {
    const response = yield call(
      api.put,
      `/admin/store/orders/${id}`,
      payload,
    )
    yield put(actions.setData('orders', response))
    notification.success({
      message: t('admin.store.notification.order.nf'),
      duration: 2,
    })
  } catch (error) {
    console.error(error)
    errorsFormat(
      error,
      t('admin.store.notification.order.errorNf')
    )
  } finally {
    yield put(actions.setLoading(false))
  }
}

export default function* () {
  yield all([
    takeLatest(types.FETCH_ALL, fetchAllSaga),
    takeLatest(types.FETCH_WALLET_PRODUCTS_ALL, fetchWalletProductsAllSaga),
    takeLatest(types.FETCH_PRODUCTS, fetchProductsSaga),
    takeLatest(types.FETCH_UNITS, fetchUnitsSaga),
    takeLatest(types.FETCH_DEFAULTERS, fetchDefaultersSaga),
    takeLatest(types.CREATE_DEFAULTER, createDefaulterSaga),
    takeLatest(types.PATCH_DEFAULTER, patchDefaulterSaga),
    takeLatest(types.CREATE_WALLET_PRODUCT, createWalletProductSaga),
    takeLatest(types.UPDATE_WALLET_PRODUCT, updateWalletProductSaga),
    takeLatest(types.FETCH_ONE, fetchOneSaga),
    takeLatest(types.SEND_DATA, sendDataSaga),
    takeLatest(types.SEND_PATCH_DATA, sendPatchDataSaga),
    takeLatest(types.GENERATE_LABELS, generateLabelsSaga),
    takeLatest(types.CANCEL_ORDER, cancelOrderSaga),
    takeLatest(types.SEND_ORDER_NF, sendOrderNfSaga),
  ])
}
