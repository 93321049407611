import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Layout, Icon } from 'antd'
import { MdMenu } from 'react-icons/md'

import { actions as actionsSideBar } from 'components/SideBar/actions'
import { actions } from 'pages/auth/actions'
import NavBar from './Navbar'
import Logo from '../Logo/Logo'
import './TopBar.sass'

const { Header } = Layout

const mapStateToProps = ({ auth, sideBar, store }) => ({ auth, sideBar, store })
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
  ...actionsSideBar,
}, dispatch)

const TopBar = ({
  sideBar: {
    current,
    collapsed,
    walletMovements,
    loading,
  },
  setSideBarProps,
  auth,
  logout,
  store,
  fetchWalletMovements,
}) => (
  <Header className="top-bar">
    <div className="top-bar-wrap">
      <div className="top-bar-logo">
        <Icon
          className="trigger"
          component={() => <MdMenu />}
          onClick={() => setSideBarProps(current, !collapsed)}
        />
        <Logo icon />
      </div>
      <NavBar
        auth={auth}
        logout={logout}
        store={store}
        fetchWalletMovements={fetchWalletMovements}
        walletMovements={walletMovements}
        loading={loading}
      />
    </div>
  </Header>
)

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)
