import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'

import MenuList from './MenuLIst'
import './SideBar.sass'

const { Sider } = Layout

const mapStateToProps = ({ sideBar }) => ({ collapsed: sideBar.collapsed })

const SideBar = ({ collapsed }) => (
  <Sider
    collapsible
    breakpoint="lg"
    collapsedWidth={(window.innerWidth <= 425) ? '0' : '80'}
    className="side-bar"
    trigger={null}
    width={220}
    collapsed={collapsed}
  >
    <MenuList />
  </Sider>
)

export default connect(mapStateToProps)(SideBar)
