import React from 'react'
import { Select } from 'antd'

const CustomSelect = ({ options, firstOption = false, ...rest }) => (
  <Select
    {...rest}
    showSearch
    optionFilterProp="children"
    style={{ width: '100%' }}
    filterOption={
      (input, option) => (
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    }
  >
    {
      firstOption
      && (
        <Select.Option
          value={firstOption.value}
          key="alloptions"
        >
          { firstOption.label }
        </Select.Option>
      )
    }
    {
      options.map(item => (
        <Select.Option
          value={item.value}
          key={item.value}
        >
          { item.label }
        </Select.Option>
      ))
    }
  </Select>
)

export default CustomSelect
