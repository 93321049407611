import { typeActions } from 'state/util'

const { actions, types } = typeActions('avant', {
  getLicenses: () => ({ }),
  setLicenses: licenses => ({ payload: licenses }),
  setLoading: value => ({ meta: { loading: value } }),
})

export {
  actions,
  types,
}
