import React from 'react'
import { Input } from 'antd'

const CustomInput = ({
  placeholder = '',
  onChange,
  value,
  disabled = false,
}) => (
  <Input
    onChange={onChange}
    placeholder={placeholder}
    value={value}
    disabled={disabled}
  />
)

export default CustomInput
