import { typeActions } from 'state/util'

const { actions, types } = typeActions('download', {
  getData: route => ({ meta: { route } }),
  setData: data => ({ payload: data }),
  setLoading: value => ({ meta: { loading: value } }),
})

export {
  actions,
  types,
}
