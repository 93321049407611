import { types } from './actions'

const INITIAL_STATE = {
  data: [],
  wallets: null,
  cart: [],
  cartOrigin: null,
  cartType: null,
  cartProperties: null,
  cartDiscount: null,
  orders: [],
  order: null,
  carriers: {},
  paymentMethods: {},
  loading: false,
  loadingKits: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ALL:
      return {
        ...state,
        data: action.payload,
      }

    case types.SET_WALLETS:
      return {
        ...state,
        wallets: action.payload,
      }

    case types.SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      }

    case types.SET_ORDER:
      return {
        ...state,
        order: action.payload,
      }

    case types.SET_LOADING:
      return {
        ...state,
        loading: action.meta.loading,
      }

    case types.SET_LOADING_KITS:
      return {
        ...state,
        loadingKits: action.meta.loadingKits,
      }

    case types.SET_CART:
      return {
        ...state,
        cart: action.payload,
      }

    case types.SET_CART_ORIGIN:
      return {
        ...state,
        cartOrigin: action.payload,
      }

    case types.SET_CART_TYPE:
      return {
        ...state,
        cartType: action.payload,
      }

    case types.SET_CART_PROPERTIES:
      return {
        ...state,
        cartProperties: action.payload,
      }

    case types.SET_REMOVE_ITEM_CART: {
      const toDelete = state.cart.findIndex(item => (
        item.id === action.meta.id
      ))

      if (toDelete < 0) {
        return state
      }

      return {
        ...state,
        cart: [
          ...state.cart.slice(0, toDelete),
          ...state.cart.slice(toDelete + 1),
        ],
      }
    }

    case types.SET_CARRIERS:
      return {
        ...state,
        carriers: action.meta,
      }

    case types.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.meta,
      }

    default:
      return state
  }
}
