import {
  all, takeLatest, put
} from 'redux-saga/effects'
import { t } from 'ab18n'
import { notification } from 'antd'

import * as axios from 'axios'
import { actions, types } from './actions'

function errorsFormat(error) {
  if (error.response && error.response.status !== 401) {
    notification.error({
      message: error.response.status === 500
        ? t('lists.notification.error')
        : error.response.data.errors.toString(),
      duration: null,
    })
  }
}

function* fetchSaga(action) {
  const { meta: { report, filter } } = action

  for (let i = 0; i < report.length; i++) {
    const item = report[i]

    yield put(actions.setAll(
      { loading: true },
      { report: item },
    ))

    try {
      let route = ''

      switch (item) {
        case 'summary':
          route = '5cc75b71320000764db951f5'
          break

        case 'gauge':
          route = '5cc74ffc3200008734b951c8'
          break

        case 'pie':
          route = '5cc30e5233000073007e564f'
          break

        case 'results':
          route = '5ccc8b053300001064e01d54'
          break

        case 'tables':
          route = '5ccc34823300000333e01b9a'
          break

        default:
          route = ''
          break
      }

      const response = yield axios.get(
        `http://www.mocky.io/v2/${route}`,
        filter ? { params: filter } : {}
      )

      const data = Array.isArray(response.data)
        ? response.data
        : [response.data]

      yield put(actions.setAll(
        {
          data: data.map((res, idx) => ({
            ...res,
            id: res.id || idx + 1,
          })),
        },
        { report: item },
      ))
    } catch (error) {
      console.error(error)
      errorsFormat(error)
    } finally {
      yield put(actions.setAll(
        { loading: false },
        { report: item },
      ))
    }
  }
}

function* fetchCountriesSaga() {
  try {
    const response = yield axios.get(
      'http://www.mocky.io/v2/5ccb48b0610000910f1623db'
    )

    yield put(actions.setCountries(response.data))
    yield put(actions.fetchStates(response.data[0]))
  } catch (error) {
    console.error(error)
    errorsFormat(error)
  }
}

function* fetchStatesSaga(action) {
  const { payload, meta } = action

  if (meta) {
    meta.loading()
  }
  try {
    const response = yield axios.get(
      'http://www.mocky.io/v2/5ccb4a0961000016031623e1',
      {
        params: payload,
      },
    )

    yield put(actions.setStates(response.data))
  } catch (error) {
    console.error(error)
    errorsFormat(error)
  } finally {
    if (meta) {
      meta.loading()
    }
  }
}

export default function* () {
  yield all([
    takeLatest(types.FETCH, fetchSaga),
    takeLatest(types.FETCH_COUNTRIES, fetchCountriesSaga),
    takeLatest(types.FETCH_STATES, fetchStatesSaga),
  ])
}
