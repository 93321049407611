import React from 'react'

import imagePlaceholder from 'assets/images/placeholder.jpg'
import storePlaceholder from 'assets/images/placeholder-store.jpg'

/* eslint-disable no-return-assign */
const placeholder = (e, inStore) => (
  e.target.src = inStore ? storePlaceholder : imagePlaceholder
)
/* eslint-enable no-return-assign */

const Image = ({ src, alt, inStore = false }) => (
  <img src={src} alt={alt} onError={e => placeholder(e, inStore)} />
)

export default Image
