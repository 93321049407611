import { types } from './actions'

const INITIAL_STATE = {
  walletProducts: [],
  products: [],
  unitsAll: [],
  defaulters: [],
  loading: false,
  modal: false,
  loadingUpdate: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_DATA:
      return {
        ...state,
        ...action.payload,
      }

    case types.SET_WALLET_PRODUCTS:
      return {
        ...state,
        walletProducts: action.payload,
      }

    case types.SET_UNITS:
      return {
        ...state,
        unitsAll: action.payload,
      }

    case types.SET_DEFAULTERS:
      return {
        ...state,
        defaulters: action.payload,
      }

    case types.EDIT_DEFAULTERS:
      return {
        ...state,
        defaulters: state.defaulters.length > 0 ? state.defaulters.map(item => ({
          ...item,
          isActive: item.unit === action.meta.unit ? !item.isActive : item.isActive
        })) : [],
      }

    case types.OPEN_MODAL:
      return {
        ...state,
        modal: !state.modal,
      }

    case types.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      }

    case types.SET_LOADING:
      return {
        ...state,
        loading: action.meta.loading,
      }

    case types.SET_LOADING_UPDATE:
      return {
        ...state,
        loadingUpdate: action.meta.loadingUpdate,
      }

    default:
      return state
  }
}
