import { typeActions } from 'state/util'

const { actions, types } = typeActions('store', {
  fetch: () => ({}),
  fetchPoints: () => ({}),
  fetchCart: (meta = null) => ({ meta }),
  fetchOrders: () => ({ }),
  fetchOrder: order => ({ meta: { order } }),
  fetchCarriers: (meta = null) => ({ meta }),
  fetchPaymentMethods: () => ({ }),
  setLoading: value => ({ meta: { loading: value } }),
  setLoadingKits: value => ({ meta: { loadingKits: value } }),
  setAll: data => ({ payload: data }),
  setWallets: data => ({ payload: data }),
  setOrders: data => ({ payload: data }),
  setOrder: data => ({ payload: data }),
  setCart: data => ({ payload: data }),
  setCartOrigin: data => ({ payload: data }),
  setCartType: data => ({ payload: data }),
  removeItemCart: id => ({ meta: { id } }),
  setRemoveItemCart: id => ({ meta: { id } }),
  updateItemCart: meta => ({ meta }),
  addToCart: (data, meta = null) => ({ payload: data, meta }),
  setCarriers: data => ({ meta: data }),
  setPaymentMethods: data => ({ meta: data }),
  checkout: (data, meta) => ({ payload: data, meta }),
  updatePrice: () => ({}),
  rePurchase: data => ({ payload: data }),
  setCartProperties: data => ({ payload: data }),
  getDiscount: data => ({ payload: data }),
  searchByProduct: data => ({ meta: data }),
  notifyMe: data => ({ payload: data })
})

export {
  actions,
  types,
}
