import { combineReducers } from 'redux'

import auth from 'pages/auth/reducer'
import reports from 'pages/Reports/reducer'
import sideBar from 'components/SideBar/reducer'
import pageHeader from 'components/PageHeader/reducer'
import avant from 'pages/Avant/reducer'
import download from 'pages/Download/reducer'
import admin from 'pages/Admin/reducer'
import store from 'pages/Store/reducer'

export default combineReducers({
  auth,
  sideBar,
  reports,
  pageHeader,
  avant,
  download,
  admin,
  store,
})
