import React from 'react'
import { Button } from 'antd'

const CustomButton = ({ value = false, ...rest }) => {
  if (value) {
    return <Button {...rest}>{ value }</Button>
  }

  return <Button {...rest} />
}

export default CustomButton
