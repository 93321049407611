import React from 'react'
import { t } from 'ab18n'
import {
  Tag,
} from 'antd'

const BillingStatus = (status) => {
  let color = ''
  let text = ''
  switch (status) {
    case 'expired':
      color = '#F44336'
      text = t('billing.status.expired')
      break

    case 'open':
      color = '#FDBE35'
      text = t('billing.status.open')
      break

    case 'paid':
      color = '#4AC964'
      text = t('billing.status.paid')
      break

    default:
      color = '#000000'
      text = t('billing.status.empty')
      break
  }

  return <Tag color={color} key={status}>{text.toUpperCase()}</Tag>
}

export default BillingStatus
