import VMasker from 'vanilla-masker'

export const cnpj = value => (
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(value, '99.999.999/9999-99') : ''
)

export const cep = value => (
  value && VMasker.toNumber(value)
    ? VMasker.toPattern(value, '99999-999') : ''
)

export const real = (value, symbol = '') => (
  value && VMasker.toNumber(value)
    ? `${symbol} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''
)
