import { typeActions } from 'state/util'

const { actions, types } = typeActions('admin', {
  fetchAll: meta => ({
    meta,
  }),
  fetchWalletProductsAll: () => ({ }),
  fetchProducts: () => ({ }),
  fetchUnits: () => ({ }),
  fetchDefaulters: () => ({ }),
  createDefaulter: data => ({ payload: data }),
  patchDefaulter: data => ({ payload: data }),
  createWalletProduct: data => ({ payload: data }),
  updateWalletProduct: (id, data) => ({ payload: id, meta: data }),
  fetchOne: (payload, meta) => ({
    payload,
    meta,
  }),
  setData: (type, payload) => ({
    payload: { [type]: payload }
  }),
  setLoading: value => ({ meta: { loading: value } }),
  editDefaulters: value => ({ meta: { unit: value } }),
  openModal: () => ({ }),
  setLoadingUpdate: value => ({ meta: { loadingUpdate: value } }),
  sendData: (data, meta = null) => ({ payload: data, meta }),
  setWalletProducts: (data, meta = null) => ({ payload: data, meta }),
  setUnits: (data, meta = null) => ({ payload: data, meta }),
  setDefaulters: (data, meta = null) => ({ payload: data, meta }),
  setProducts: (data, meta = null) => ({ payload: data, meta }),
  sendPatchData: (data, meta = null) => ({ payload: data, meta }),
  generateLabels: data => ({ meta: { id: data } }),
  cancelOrder: meta => ({ meta }),
  sendOrderNf: (data, meta) => ({ payload: data, meta })
})

export {
  actions,
  types,
}
