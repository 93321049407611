import React from 'react'
import { Input } from 'antd'

import './QuantityComponent.sass'

const { Group } = Input

const QuantityComponent = ({
  value, onChange, max = 0, min = 0, disabled,
}) => {
  const handleChange = (targetValue) => {
    const converted = Number(targetValue)
    if (!Number.isNaN(converted)) {
      onChange(Math.max(min, Math.min(max, targetValue)))
    }
  }

  const handleEvent = e => handleChange(e.target.value)
  const decrement = () => handleChange(value - 1)
  const increment = () => handleChange(value + 1)

  return (
    <Group className="quantity-input-group" style={{ display: 'flex' }}>
      <Input
        disabled={disabled || value === min}
        type="button"
        onClick={decrement}
        value="-"
      />
      <Input
        disabled={disabled}
        style={{ textAlign: 'center', width: 55 }}
        onChange={e => handleEvent(e)}
        value={value}
      />
      <Input
        disabled={disabled || value === max}
        type="button"
        onClick={increment}
        value="+"
      />
    </Group>
  )
}

export default QuantityComponent
