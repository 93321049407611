import { typeActions } from 'state/util'

const { actions, types } = typeActions('search', {
  setTerm: term => ({ meta: { term } }),
})

export {
  actions,
  types,
}
