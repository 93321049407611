import {
  all, takeLatest, put, call,
} from 'redux-saga/effects'
import { t } from 'ab18n'

import api from 'services/api'
import errorsFormat from 'shared/errors-format'
import { actions, types } from './actions'

function* getDataSaga(action) {
  const { meta: { route } } = action
  yield put(actions.setLoading(true))

  try {
    const response = yield call(api.get, `/downloads/${route}`)
    yield put(actions.setData({
      data: Object.values(response.data).map((item, key) => ({
        ...item,
        id: +Object.keys(response.data)[key]
      }))
    }))
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('general.notification.error'), 2)
  } finally {
    yield put(actions.setLoading(false))
  }
}

export default function* () {
  yield all([
    takeLatest(types.GET_DATA, getDataSaga),
  ])
}
