import React from 'react'
import { Checkbox } from 'antd'

const CustomCheckbox = ({ onChange, value, label }) => (
  <Checkbox
    onChange={() => onChange({ target: { value: !value } })}
    checked={value}
  >
    { label }
  </Checkbox>
)

export default CustomCheckbox
