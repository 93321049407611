import React, { Component } from 'react'
import { Spin, Card } from 'antd'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
/* eslint-disable camelcase */
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
/* eslint-enable camelcase */

import colors from 'shared/colors'
import './Charts.sass'

am4core.useTheme(am4themes_animated)

class Pie extends Component {
  componentDidMount() {
    this.setChart()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.reports === undefined
      || prevProps.reports.data === undefined
    ) {
      this.setChart()
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose()
    }
  }

  setChart() {
    const { reports, chartId, legendId } = this.props

    if (reports && reports.data) {
      am4core.options.autoSetClassName = true

      const chart = am4core.create(chartId, am4charts.PieChart3D)

      chart.hiddenState.properties.opacity = 0
      chart.data = reports.data

      const pieSeries = chart.series.push(new am4charts.PieSeries3D())
      pieSeries.dataFields.category = 'item'
      pieSeries.dataFields.value = 'value'
      pieSeries.labels.template.text = ''
      pieSeries.ticks.template.disabled = true
      pieSeries.colors.list = colors

      chart.legend = new am4charts.Legend()
      const legendContainer = am4core.create(legendId, am4core.Container)
      legendContainer.width = am4core.percent(100)
      legendContainer.height = am4core.percent(100)
      chart.legend.parent = legendContainer

      const element = document.getElementById(legendId)

      const resizeLegend = () => {
        if (chart.legend.contentHeight) {
          element.style.height = `${chart.legend.contentHeight}px`
        }
      }

      chart.events.on('datavalidated', resizeLegend)
      chart.events.on('maxsizechanged', resizeLegend)

      this.chart = chart
    }
  }

  render() {
    const {
      reports, chartId, legendId, title
    } = this.props

    return (
      <Spin spinning={reports && reports.loading}>
        <Card title={title}>
          <div className="chart-content" id={chartId} />
          <div className="legend-wrapper">
            {
              reports && reports.data
              && (
                <div
                  className="legend-chart"
                  style={{ height: (reports.data.length * 60) }}
                  id={legendId}
                />
              )
            }
          </div>
        </Card>
      </Spin>
    )
  }
}

export default Pie
