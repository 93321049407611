import React from 'react'
import { Modal } from 'antd'

import './CustomModal.sass'

const CustomModal = ({ children, footer = false, ...rest }) => (
  <Modal footer={footer} {...rest}>
    { children }
  </Modal>
)

export default CustomModal
