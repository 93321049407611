import {
  all, takeLatest, put, call,
} from 'redux-saga/effects'
import { t } from 'ab18n'

import api from 'services/api'
import errorsFormat from 'shared/errors-format'
import { actions, types } from './actions'

function* getLicensesSaga() {
  yield put(actions.setLoading(true))

  try {
    const response = yield call(api.get, 'http://www.mocky.io/v2/5cc73421320000fc0db9514e')
    yield put(actions.setLicenses({
      licenses: response.data,
    }))
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('general.notification.error'), 2)
  } finally {
    yield put(actions.setLoading(false))
  }
}

export default function* () {
  yield all([
    takeLatest(types.GET_LICENSES, getLicensesSaga),
  ])
}
