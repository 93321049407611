import { typeActions } from 'state/util'

const { actions, types } = typeActions('reports', {
  fetch: (report, filter) => ({ meta: { report, filter } }),
  fetchCountries: () => ({}),
  fetchStates: (data, meta = null) => ({
    payload: data,
    meta,
  }),
  setAll: (data = null, meta = null, error = null) => ({
    payload: data,
    meta,
    error,
  }),
  setCountries: (data = null) => ({
    payload: data,
  }),
  setStates: (data = null) => ({
    payload: data,
  }),
  setFilters: (data = null) => ({
    payload: data,
  }),
})

export {
  actions,
  types,
}
