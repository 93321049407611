import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { t } from 'ab18n'
import { Input } from 'antd'

import { actions } from './actions'
import './PageHeader.sass'

const { Search } = Input

const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
}, dispatch)

const PageHeader = ({ title, search, setTerm }) => {
  const onChange = term => (
    setTerm(term)
  )

  return (
    <div className="page-header">
      <div className="page-header-left">
        <h1>{ title }</h1>
      </div>
      {
        search
        && (
          <div className="page-header-right">
            <Search
              allowClear
              placeholder={t('pageHeader.search')}
              style={{ width: '100%' }}
              onChange={e => onChange(e.target.value)}
            />
          </div>
        )
      }
    </div>
  )
}

export default connect(null, mapDispatchToProps)(PageHeader)
