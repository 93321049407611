import React from 'react'
import { t } from 'ab18n'
import { CustomModal } from 'components'
import {
  Divider,
  Row,
  Col,
  Skeleton,
  Tag,
  Empty,
} from 'antd'
import moment from 'moment'
import { real } from 'shared/mask'

import './PointsModal.sass'

const PointsModal = ({
  walletMovements,
  visible,
  hideModal,
  loading,
}) => {
  let dateCurrent = 0
  const dividerDate = (date) => {
    if (date !== dateCurrent) {
      dateCurrent = date
      return (<Divider>{date}</Divider>)
    }
    return null
  }

  const types = {
    1: {
      type: '+', name: t('store.points.status.received'), tag: 'rgb(47 160 0 / 80%)', color: 'green'
    },
    2: {
      type: '-', name: t('store.points.status.exchange'), tag: 'rgb(255 0 0 / 50%)', color: 'red'
    },
    3: {
      type: '+', name: t('store.points.status.reversal'), tag: 'rgb(47 160 0 / 80%)', color: 'green'
    },
    4: {
      type: '-', name: t('store.points.status.canceled'), tag: '#a0a0a0', color: '#a0a0a0'
    },
    5: {
      type: '-', name: t('store.points.status.pending'), tag: 'rgb(255 0 0 / 50%)', color: 'red'
    },
    6: {
      type: '+', name: t('store.points.status.deposit'), tag: 'rgb(47 160 0 / 80%)', color: 'green'
    },
  }

  const status = (item) => {
    if (item.canceled) {
      return 4
    } if (item.pending) {
      return 5
    }
    return item.movementType.id
  }

  const classCanceled = type => (type === 4 ? 'canceledText' : '')

  const description = item => (
    <div key={item.id}>
      {dividerDate(moment(item.updatedAt).format('L'))}
      <div style={{ marginBottom: '20px' }}>
        <Row>
          <Col span={7} className="value-content">
            <span className={classCanceled(status(item))}>
              <span style={{ color: types[status(item)].color }}>{types[status(item)].type}</span>
              {real(item.value)} SD CASH
            </span>
          </Col>
          <Col span={14} className="value-line" />
          <Col span={3} className="time">
            <span className={classCanceled(status(item))}>
              {moment(item.updatedAt).format('LT')}
            </span>
          </Col>
        </Row>
        <div className="description">
          <span className={classCanceled(status(item))}>{item.description}</span>
        </div>
        <div className="description" style={{ color: types[item.movementType.id].color }}>
          <Tag className="tagTypes" color={types[status(item)].tag}>
            <span className={status(item)}>{types[status(item)].name}</span>
          </Tag>
          <span className={classCanceled(status(item))}>
            {t(
              'store.points.calculation', {
                value: real(item.previousBalance),
                previousBalance: item.movementType.id === 2
                  ? real(item.previousBalance - item.value) : real(item.previousBalance + item.value)
              }
            )}
          </span>
        </div>
      </div>
    </div>
  )
  return (
    <CustomModal
      title={t('store.points.model.title')}
      visible={visible}
      onCancel={() => hideModal()}
      className="points-model"
    >
      <Skeleton loading={loading} active>
        {walletMovements.length > 0 ? walletMovements.map(item => (
          description(item)
        )) : <Empty description={t('store.points.model.empty')} />}
      </Skeleton>
    </CustomModal>
  )
}

export default PointsModal
