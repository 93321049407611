import { all } from 'redux-saga/effects'

import authSaga from 'pages/auth/saga'
import avantSaga from 'pages/Avant/saga'
import downloadSaga from 'pages/Download/saga'
import reportsSaga from 'pages/Reports/saga'
import adminSaga from 'pages/Admin/saga'
import storeSaga from 'pages/Store/saga'
import sideBarSaga from 'components/SideBar/saga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    avantSaga(),
    downloadSaga(),
    reportsSaga(),
    adminSaga(),
    storeSaga(),
    sideBarSaga(),
  ])
}
