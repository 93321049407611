import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Menu, Icon } from 'antd'

import { actions } from './actions'
import menuItems from './menu-items'

const { SubMenu } = Menu

const mapStateToProps = ({ sideBar, auth }) => ({ sideBar, auth })
const mapDispatchToProps = dispatch => bindActionCreators({
  ...actions,
}, dispatch)

const MenuList = ({
  setSideBarProps,
  sideBar: {
    current,
    collapsed,
  },
  auth,
}) => (
  <Menu theme="light" mode="inline" selectedKeys={[`${current}`]}>
    {
      auth && auth.data && (
        menuItems(auth).map(menu => (
          menu.children
            ? (
              <SubMenu
                key={menu.key}
                title={(
                  <span>
                    {
                      typeof menu.icon === 'string'
                        ? <Icon type={menu.icon} />
                        : <Icon component={() => <menu.icon />} />
                    }
                    <span>{ menu.text }</span>
                  </span>
                )}
              >
                {
                  menu.children.map(submenu => (
                    submenu.title
                      ? (
                        <SubMenu key={submenu.title} title={submenu.title}>
                          {submenu.items.map(item => (
                            <Menu.Item
                              key={item.key}
                              onClick={() => setSideBarProps(
                                item.key,
                                (window.innerWidth <= 425) ? !collapsed : collapsed
                              )}
                            >
                              <Link to={item.router}>
                                <span>{ item.text }</span>
                              </Link>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      )
                      : (
                        <Menu.Item
                          key={submenu.key}
                          onClick={() => setSideBarProps(
                            submenu.key,
                            (window.innerWidth <= 425) ? !collapsed : collapsed
                          )}
                        >
                          <Link to={submenu.router}>
                            <span>{ submenu.text }</span>
                          </Link>
                        </Menu.Item>
                      )
                  ))
                }
              </SubMenu>
            )
            : (
              <Menu.Item
                key={menu.key}
                onClick={() => setSideBarProps(
                  menu.key,
                  (window.innerWidth <= 425) ? !collapsed : collapsed
                )}
              >
                <Link to={menu.router}>
                  {
                    typeof menu.icon === 'string'
                      ? <Icon type={menu.icon} />
                      : <Icon component={() => <menu.icon />} />
                  }
                  <span>{ menu.text }</span>
                </Link>
              </Menu.Item>
            )
        ))
      )
    }
  </Menu>
)

export default connect(mapStateToProps, mapDispatchToProps)(MenuList)
