import React from 'react'
import { t } from 'ab18n'
import { Tag } from 'antd'

const colors = (type) => {
  const typeColor = {
    1: '#03A9F4',
    2: '#4CAF50',
  }

  return typeColor[type]
}

const CustomTag = ({ type, style = {} }) => (
  <Tag color={colors(type)} style={style}>
    { t(`store.types.${type}`) }
  </Tag>
)

export default CustomTag
