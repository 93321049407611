import React from 'react'
import {
  Tabs,
  Divider,
  Spin,
} from 'antd'

const CustomTabs = ({ tabs, loading }) => (
  <Spin spinning={loading}>
    {
      !loading
      && (
        <Tabs defaultActiveKey={tabs[0].id}>
          {
            tabs.map(item => (
              <Tabs.TabPane tab={item.title} key={item.id}>
                <h3>{ item.title }</h3>
                <Divider />
                { item.content }
              </Tabs.TabPane>
            ))
          }
        </Tabs>
      )
    }
  </Spin>
)

export default CustomTabs
