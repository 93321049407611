import { t } from 'ab18n'

const menuItems = (auth) => {
  const storeChildren = [
    {
      key: 1.1,
      text: t('menu.store.kits'),
      router: '/store/kits',
    },
    {
      key: 1.2,
      icon: 'file-text',
      text: t('menu.store.orders'),
      router: '/store/orders',
    },
  ]

  let storeRoleFinancial = []
  if (auth && auth.data && auth.data.roles.includes('ROLE_ADMIN_FINANCIAL')) {
    storeRoleFinancial = [
      {
        key: 1.6,
        text: t('menu.admin.financial'),
        router: '/admin/store/finances',
      },
    ]
  }

  let storeAdminChildren = []
  let storeWalletChildren = []
  if (auth && auth.data && auth.data.isStoreAdmin) {
    storeAdminChildren = [
      {
        key: 1.3,
        text: t('menu.admin.kits'),
        router: '/admin/store',
      },
      {
        key: 1.4,
        text: t('menu.admin.orders'),
        router: '/admin/store/orders',
      },
      {
        key: 1.5,
        text: t('menu.admin.promotions'),
        router: '/admin/store/promotions',
      },
    ]

    if (auth.data.isAllowedToManagePoints) {
      storeWalletChildren = [
        {
          title: t('menu.admin.wallet.title'),
          items: [
            {
              key: 1.6,
              text: t('menu.admin.wallet.points'),
              router: '/admin/store/wallet/points',
            },
            {
              key: 1.7,
              text: t('menu.admin.wallet.products'),
              router: '/admin/store/wallet/products',
            },
          ],
        }
      ]
    }
  }

  const store = [
    {
      key: 1,
      icon: 'shop',
      text: t('menu.store.title'),
      children: [
        ...storeChildren,
        ...storeAdminChildren,
        ...storeRoleFinancial,
        ...storeWalletChildren
      ]
    },
  ]

  return [
    ...store,
  ]
}

export default menuItems
