import {
  all, takeLatest, put, call
} from 'redux-saga/effects'
import { t } from 'ab18n'

import errorsFormat from 'shared/errors-format'
import api from 'services/api'
import { actions, types } from './actions'

function* fetchWalletMovementsSaga(action) {
  const { payload } = action
  yield put(actions.setLoading(true))
  try {
    const response = yield call(
      api.get,
      `/store/wallets/${payload}/movements`,
    )
    yield put(actions.setWalletMovements(response.data))
  } catch (error) {
    console.error(error)
    errorsFormat(error, t('general.notification.error'))
  } finally {
    yield put(actions.setLoading(false))
  }
}

export default function* () {
  yield all([
    takeLatest(types.FETCH_WALLET_MOVEMENTS, fetchWalletMovementsSaga),
  ])
}
