import * as am4core from '@amcharts/amcharts4/core'

export default [
  am4core.color('#3F51B5'),
  am4core.color('#03A9F4'),
  am4core.color('#00BCD4'),
  am4core.color('#009688'),
  am4core.color('#4CAF50'),
  am4core.color('#795548'),
  am4core.color('#2196F3'),
  am4core.color('#8BC34A'),
  am4core.color('#F44336'),
  am4core.color('#673AB7'),
  am4core.color('#9C27B0'),
  am4core.color('#CDDC39'),
  am4core.color('#FFEB3B'),
  am4core.color('#FFC107'),
  am4core.color('#FF9800'),
  am4core.color('#FF5722'),
  am4core.color('#E91E63'),
  am4core.color('#9E9E9E'),
  am4core.color('#607D8B'),
]
