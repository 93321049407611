import React, { Component } from 'react'
import { Spin, Card } from 'antd'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
/* eslint-disable camelcase */
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4langPtBR from '@amcharts/amcharts4/lang/pt_BR'
/* eslint-enable camelcase */

import './Charts.sass'

am4core.useTheme(am4themes_animated)

class Gauge extends Component {
  componentDidMount() {
    this.resize()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.reports === undefined
      || prevProps.reports.data === undefined
    ) {
      this.setChart()
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose()
    }
  }

  setChart() {
    const { reports, chartId } = this.props
    const chart = am4core.create(chartId, am4charts.GaugeChart)

    am4core.options.autoSetClassName = true
    chart.language.locale = am4langPtBR
    chart.innerRadius = -5
    chart.startAngle = -200
    chart.endAngle = 20

    if (reports && reports.data) {
      const data = reports.data[0]

      /**
       * Normal axis
       */
      const axis = chart.xAxes.push(new am4charts.ValueAxis())
      axis.min = 0
      axis.max = 100
      axis.strictMinMax = true
      axis.renderer.radius = am4core.percent(100)
      axis.renderer.ticks.template.length = 10
      axis.renderer.labels.template.radius = 5
      axis.renderer.labels.template.adapter.add('text', text => `${text}%`)

      const axis2 = chart.xAxes.push(new am4charts.ValueAxis())
      axis2.min = 0
      axis2.max = 100
      axis2.renderer.innerRadius = 10
      axis2.strictMinMax = true
      axis2.renderer.labels.template.disabled = true
      axis2.renderer.ticks.template.disabled = true
      axis2.renderer.grid.template.disabled = true

      const range0 = axis2.axisRanges.create()
      range0.value = data.low.min
      range0.endValue = data.low.max
      range0.axisFill.fillOpacity = 1
      range0.axisFill.fill = am4core.color('#D77070')

      const range1 = axis2.axisRanges.create()
      range1.value = data.medium.min
      range1.endValue = data.medium.max
      range1.axisFill.fillOpacity = 1
      range1.axisFill.fill = am4core.color('#FDDC40')

      const range2 = axis2.axisRanges.create()
      range2.value = data.high.min
      range2.endValue = data.high.max
      range2.axisFill.fillOpacity = 1
      range2.axisFill.fill = am4core.color('#9EC684')

      /**
       * Hand
       */
      const hand = chart.hands.push(new am4charts.ClockHand())
      hand.axis = axis2
      hand.innerRadius = am4core.percent(50)
      hand.startWidth = 10
      hand.pin.disabled = true
      hand.value = data.total

      /**
       * Label
       */
      const label = chart.radarContainer.createChild(am4core.Label)
      label.isMeasured = false
      label.fontSize = 18
      label.x = am4core.percent(50)
      label.horizontalCenter = 'middle'
      label.verticalCenter = 'bottom'
      label.text = `${Math.round(hand.value)}%`
    }

    this.chart = chart
  }

  resize = () => {
    this.setChart()
  }

  render() {
    const { reports, chartId, title } = this.props

    return (
      <Spin spinning={reports && reports.loading}>
        <Card className="card-grid-small" title={title}>
          <div className="chart-content-gauge" id={chartId} />
        </Card>
      </Spin>
    )
  }
}

export default Gauge
