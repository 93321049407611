import React from 'react'
import { t } from 'ab18n'
import { Table, Card } from 'antd'

const CustomTable = ({
  rowKey,
  title,
  loading,
  columns,
  dataSource,
  paginationSimple = false,
  pagination = false,
  ...rest
}) => (
  <Card title={title}>
    <div style={{ overflowX: 'hidden' }}>
      <Table
        {...rest}
        style={{ width: '100%' }}
        size="middle"
        rowKey={rowKey}
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination && {
          simple: (paginationSimple),
          showSizeChanger: true,
          defaultPageSize: 10,
          pageSizeOptions: ['10', '25', '50', '100'],
          total: dataSource && dataSource.length,
          locale: { items_per_page: t('table.perPage') },
          showTotal: (total, range) => (
            `${range[0]}-${range[1]}
            ${t('table.of')}
            ${total} ${t('table.items')}`
          ),
        }}
      />
    </div>
  </Card>
)

export default CustomTable
