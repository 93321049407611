import React from 'react'
import { c, n } from 'ab18n'
import { Card, Statistic } from 'antd'

import './Charts.sass'

const Summary = ({ summary }) => (
  <Card>
    {
      summary.data.map(item => (
        <Card.Grid className="gridStyle" key={item.id}>
          <Statistic title={item.title} value={c(n(item.value))} />
        </Card.Grid>
      ))
    }
  </Card>
)

export default Summary
