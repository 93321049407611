import React, { Component } from 'react'
import { Button } from 'antd'
import { t } from 'ab18n'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import oauth from 'services/oauth'
import logo from 'assets/images/logo.png'
import asideImageSquare from 'assets/images/fachada.png'
import awards from 'assets/images/selos.png'
import { actions } from './actions'
import './Login.sass'

const mapStateToProps = state => ({ ...state })
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

class Login extends Component {
  async componentWillMount() {
    const isLoggedIn = await oauth.isLoggedIn()
    if (isLoggedIn) {
      const { goHome } = this.props
      goHome()
    }
  }

  componentDidMount() {
    if (window.gapi) {
      oauth.init()
    }
  }

  onClick = () => {
    const { setStatus } = this.props
    setStatus({
      status: {
        loading: false,
        disabled: true,
        error: null,
      },
    })
  }

  render() {
    const { auth } = this.props
    const { loading, disabled, error } = auth.status

    return (
      <div className="login">
        <div className="login-top-bar">
          <h1 className="login-title">{ t('auth.pageTitle') }</h1>
        </div>

        <section className="login-section">
          <div className="login-wrapper">
            <div className="login-aside">
              <figure className="login-aside-image">
                <img src={asideImageSquare} alt="Sobrancelhas Design" />
              </figure>
            </div>
            <div className="login-box">
              <div className="login-logo">
                <div className="login-logo-image">
                  <img src={logo} alt={t('general.logo')} />
                </div>
                <h2 className="login-logo-title">{ t('general.logo') }</h2>
              </div>

              <div className="login-welcome">
                <p>{ t('auth.welcome') }</p>
                <p>{ t('auth.instruction') }</p>
              </div>

              <Button
                id="login-btn"
                className="login-btn"
                disabled={disabled}
                loading={loading}
                onClick={this.onClick}
              >
                { t('auth.login') }
              </Button>
              { error && <p className="login-error">{ error }</p> }
            </div>
          </div>

          <div className="login-bottom">
            <div className="sd-group">
              <img src={awards} alt="Grupo SD" />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
