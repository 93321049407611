import { types } from './actions'

const INITIAL_STATE = {
  filters: null,
  countries: [],
  states: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ALL:
      return {
        ...state,
        [action.meta.report]: {
          ...state[action.meta.report],
          ...action.payload,
        },
      }

    case types.SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      }

    case types.SET_STATES:
      return {
        ...state,
        states: action.payload,
      }

    case types.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }

    default:
      return state
  }
}
