import { types } from './actions'

const INITIAL_STATE = {
  licenses: [],
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_LICENSES:
      return {
        ...state,
        ...action.payload,
      }

    case types.SET_LOADING:
      return {
        ...state,
        loading: action.meta.loading,
      }

    default:
      return state
  }
}
